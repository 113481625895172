import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import Phone from '@catalogo/ui-americanas-desktop-svg/phone.svg';
import Mail from '@catalogo/ui-americanas-desktop-svg/mail.svg';
import Question from '@catalogo/ui-americanas-desktop-svg/questionmark.svg';

const HasNoProductsError = ({ content }) => (
  <Container>
    <Hero>
      <HeroTitle>
        poxa, nenhum resultado encontrado para <strong>{`"${content}"`}</strong>.
      </HeroTitle>
      <HeroSubtitle>Que tal pesquisar de novo seguindo as dicas abaixo? ;)</HeroSubtitle>
      <List>
        <ListItem>Confira se o termo foi digitado certinho;</ListItem>
        <ListItem>Use menos palavras ou termos menos específicos;</ListItem>
        <ListItem>
          Tente outro produto ou navegue pelos departamentos <br /> para encontrar o que você precisa.
        </ListItem>
      </List>
    </Hero>

    <Contact>
      <Content>
        <Title>precisa de ajuda? fale com a gente</Title>
        <Text>
          Nosso atendimento é de segunda a sexta, das <br />
          9h às 18h :)
          <br />
        </Text>
      </Content>

      <Content>
        <Subtitle>
          <PhoneUI />
          por telefone:
        </Subtitle>
        <Text>
          Capitais e regiões metropolitanas: 4003 2399* <br />
          Outras regiões: 041 11 4003 2399*
        </Text>
      </Content>

      <Content>
        <Subtitle>
          <MailUI />
          por e-mail:
        </Subtitle>
        <Text>atendimento.b2b@empresas.americanas.com</Text>
        <Info>
          <QuestionUI />
          Se preferir, acesse nossas <Link to="/hotsite/empresas-site-atendimento">perguntas frequentes</Link> ;)
        </Info>
      </Content>
    </Contact>
  </Container>
);

HasNoProductsError.propTypes = {
  content: PropTypes.string,
};

const Container = styled.div`
  padding: 40px 0;
  width: 100%;
  color: #333;
`;

const Hero = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

const HeroTitle = styled.span`
  display: block;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 32px;
`;

const HeroSubtitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 24px;
  margin-bottom: 16px;
`;

const List = styled.ul``;

const ListItem = styled.li`
  font-size: 16px;
  line-height: 22px;
  list-style-type: disc;
`;

const Contact = styled.section`
  display: flex;
  background-color: #fafafa;
  border-radius: 8px;
  width: 100%;
  padding: 24px;
  align-items: start;
  justify-content: space-between;
`;

const Content = styled.div``;

const Title = styled.strong`
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 4px;
`;

const Subtitle = styled.strong`
  display: block;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
  position: relative;
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 18px;
`;

const Info = styled.p`
  font-size: 16px;
  line-height: 18px;
  margin: 20px 0 0;
  position: relative;
`;

const Link = styled(Nav)`
  font-weight: bold;
  color: #f80032;
`;

const SvgStyles = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -32px;
`;

const PhoneUI = styled(Phone)(SvgStyles);

const MailUI = styled(Mail)(SvgStyles);

const QuestionUI = styled(Question)(SvgStyles);

export default HasNoProductsError;
